import {gql} from "graphql.macro";
import {kycFormFragment} from "./fragments";

// FORM without auth
export const getKycForm = gql`
    query getKycForm($token: String!) {
        getKycForm(token: $token) {
            ...kycFormFragment
        }
    }
    ${kycFormFragment}
`

export const submitKycForm = gql`
    mutation submitKycForm($token: String!, $fields: [TKycTemplateFieldFillableModelInput!]!) {
        submitKycForm(token: $token, fields: $fields) {
#            redirectUrl
            message
        }
    }
`

export const rejectKycForm = gql`
    mutation rejectKycForm($token: String!) {
        rejectKycForm(token: $token) {
            message
        }
    }
`
