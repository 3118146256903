// Core
import {combineReducers} from 'redux';

// Reducers
import {kycFormReducer} from "../../newDomain/components/kyc/store/form/store";
import {I18NextReducer} from "../hooks/useMainTranslationHooks/slice";
import {snackReducer} from "../../newDomain/barsEnvironment/snack/store/slice";
import {messageDialogReducerReducer} from "../../newDomain/barsEnvironment/MessageDialog/store/store";

export const rootReducer = combineReducers({
    messageDialog: messageDialogReducerReducer,
    snack: snackReducer,
    I18Next: I18NextReducer,
    kycForm: kycFormReducer,
});


export type AppState = ReturnType<typeof rootReducer>;
