import React, {useState} from "react";

export const useFileDropZone = () => {
    const ref = React.createRef<HTMLInputElement>();
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);

    const fileHandler = (files: FileList | null) => {
        if(files){
            setUploadedFile(files[0]);
        }
    };

    const handleDeleteFile = () => setUploadedFile(null);

    const filePicker = () => ref.current && ref.current.click();

    return{
        filePicker,
        ref,
        fileHandler,
        handleDeleteFile,
        uploadedFile
    }
}