import {
    LICENSE_PATH,
    PATH_LOCAL_2FA,
    PATH_LOCAL_KYC_TOKEN,
    PATH_LOCAL_LOGIN,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_SHARE_REPORTS,
    PATH_LOCAL_SIGNUP
} from "../../../../newShared/constants";

export const unprotectedTopBarPaths: (string | { (pathname: string): boolean })[]  = [
    PATH_LOCAL_SHARE_REPORTS,
    // ACCEPT_INVITATION_UNPROTECTED,
    PATH_LOCAL_KYC_TOKEN,
];

export const hideProtectedTopBarPaths: (string | { (pathname: string): boolean })[] = [
    ...unprotectedTopBarPaths,
    PATH_LOCAL_PASSWORD_RESET,
    PATH_LOCAL_SIGNUP,
    PATH_LOCAL_LOGIN,
    LICENSE_PATH,
    PATH_LOCAL_2FA,
    // ACCEPT_INVITATION_UNPROTECTED,
];

export const showProtectedTopBar = (pathname: string) => !hideProtectedTopBarPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
export const showUnProtectedTopBar = (pathname: string) => unprotectedTopBarPaths.some(el => typeof el === 'string' ? el === pathname : el(pathname));
export const hideTopBar = (pathname: string) => !(showProtectedTopBar(pathname) || showUnProtectedTopBar(pathname));
