//Core
import React, {FC} from "react";

//Router
import {Redirect, Route, Switch} from 'react-router';
import {PATH_LOCAL_KYC_TOKEN} from "../../../newShared/constants";

import {KycForm} from "../../components/kyc/pages/kycForm";


export const Routes: FC = () => {
    return <Switch>
        <Route path={PATH_LOCAL_KYC_TOKEN} component={KycForm}/>

        {/*REDIRECT*/}
        <Redirect to={PATH_LOCAL_KYC_TOKEN}/>
    </Switch>;
}
