import {AxiosResponse} from "axios";
import {print} from "graphql";
import axiosSync from "../../../../newShared/utils/axios/axios";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {PATH_SERVER} from "../../../../newShared/constants";

import {getKycForm, rejectKycForm, submitKycForm,} from "./query";
import {
    GetKycFormQuery,
    GetKycFormQueryVariables,
    RejectKycFormMutation,
    RejectKycFormMutationVariables,
    SubmitKycFormMutation,
    SubmitKycFormMutationVariables,
} from "../../../../newShared/GQLTypes";
import {gql} from "@apollo/react-hooks";
import {KycDictionaryType} from "../types";

export const kycGetFormByTokenApi = async (data: GetKycFormQueryVariables, signal?: AbortSignal): Promise<GetKycFormQuery["getKycForm"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(getKycForm),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycForm
    })
)

export const kycSubmitFormByTokenApi = async (data: SubmitKycFormMutationVariables, signal?: AbortSignal): Promise<SubmitKycFormMutation["submitKycForm"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(submitKycForm),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.submitKycForm
    })
)

export const kycRejectFormByTokenApi = async (data: RejectKycFormMutationVariables, signal?: AbortSignal): Promise<RejectKycFormMutation["rejectKycForm"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(rejectKycForm),
                variables: data
            },
            {
                headers: buildCommonHeader(),
                signal
            },
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.rejectKycForm
    })
)


//getKycDictionaries
export const getKycDictionaries = async (): Promise<KycDictionaryType> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getKycDictionaries{
                      getKycDictionaries{
                        country{
                            key
                            value
                            order
                        }
                      }
                    }
                `),
                variables: {
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getKycDictionaries;
    })
)