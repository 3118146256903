import React, {FC} from "react";
import {AppBar, Toolbar} from "@mui/material";
import {useMedia} from "../../../newShared/hooks/useMedia";
import {TobBarLogo} from "../../../newShared/components/TobBarLogo";

type TProps = {
    isHidden: boolean,
}
export const TopBar: FC<TProps> = ({isHidden}) => {
    const {matches_1440Down} = useMedia();

    return !isHidden ? (
        <AppBar position={"static"} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} style={{minHeight: matches_1440Down ? '48px' : undefined}}>
            <Toolbar>
                <TobBarLogo/>

            </Toolbar>
        </AppBar>
    ) : null
}