//Core
import React, {Suspense} from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import {ApolloProvider} from '@apollo/client';

//Other
import {store} from '../../newShared/redux';
import {client} from "../../newShared/graphQl";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';


//SnackBar
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import theme from '../../newShared/theme/theme';

//I18NEXT
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'
import {Spinner} from "../../newShared/components/Spinner";
import {Shell} from "../../newDomain/barsEnvironment/shell";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {initLabels} from "../../newShared/env";

//I18
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ['en', 'he'],
        fallbackLng: 'en',
        backend: {
            loadPath: '/assets/newLocales/{{lng}}/translation.json?' + new Date().getTime(), // Add timestamp to avoid caching
        },
        detection: {
            order: ['cookie'],
            caches: ['cookie']
        },
        missingKeyNoValueFallbackToKey: true
        // react: {useSuspense: false},
    })

export default function App() {
    initLabels();

    return (
        <Suspense fallback={<Spinner />}>
            <ApolloProvider client={client}>
                <Provider store={store}>
                    <StyledThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <Router>
                                <Shell/>
                            </Router>
                        </LocalizationProvider>
                    </StyledThemeProvider>
                </Provider>
            </ApolloProvider>
        </Suspense>
    );
}
