import {useDispatch, useSelector} from "react-redux";
import {
    isLoadingSelector,
    kycDictionaries,
    kycFormResultSelector,
    kycFormSelector,
    TKYCFormSlice
} from "../../store/form/store";
import {
    GetKycFormQueryVariables,
    RejectKycFormMutationVariables,
    SubmitKycFormMutationVariables, TKycTemplateFieldFillableModelInput
} from "../../../../../newShared/GQLTypes";
import {
    GetKycDictionaries,
    kycGetFormByTokenAction,
    kycRejectFormByTokenAction,
    kycSubmitFormByTokenAction
} from "../../store/form/actions";
import {removeTimeZoneDateFields} from "../../helpers";
import { KycDictionaryType } from "../../types";

type TResponse = {
    formData: TKYCFormSlice["form"];
    result: TKYCFormSlice["result"];
    isLoading: TKYCFormSlice["isLoading"];

    getForm: (data: GetKycFormQueryVariables, signal: AbortSignal) => void;
    rejectForm: (data: RejectKycFormMutationVariables, signal: AbortSignal) => void;
    submitForm: (data: SubmitKycFormMutationVariables, signal: AbortSignal) => void;
    dicts: KycDictionaryType;
    getDicts: () => void
}
export const useKYCForm = (): TResponse => {
    const dispatch = useDispatch();

    const form = useSelector(kycFormSelector);

    const result = useSelector(kycFormResultSelector);
    const isLoading = useSelector(isLoadingSelector);
    const dicts = useSelector(kycDictionaries);

    const getForm: TResponse["getForm"] = (data, signal) => {dispatch(kycGetFormByTokenAction({data, signal}))};
    const rejectForm: TResponse["rejectForm"] = (data, signal) => {dispatch(kycRejectFormByTokenAction({data, signal}))};
    const submitForm: TResponse["submitForm"] = (data, signal) => {
        const fields: TKycTemplateFieldFillableModelInput[] = Array.isArray(data.fields) ? data.fields : [data.fields];
        const normalizedFields = removeTimeZoneDateFields(fields);
        dispatch(kycSubmitFormByTokenAction({data: {...data, fields: normalizedFields}, signal}))
    };

    const getDicts = () => dispatch(GetKycDictionaries());

    return {
        formData: form,
        result,
        isLoading,

        getForm,
        rejectForm,
        submitForm,
        dicts,
        getDicts
    }
}