import React, {FC, useEffect} from "react";
import {Autocomplete, Box, Skeleton, TextField, Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {DoNotDisturb} from "@mui/icons-material";
import {useKYCForm} from "../../hooks/useKYCForm";
import {useHistory} from "react-router";
import {readFilter} from "../../../../../newShared/utils/pagination";
import {LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import colors from "../../../../../newShared/theme/colors";
import {FileDropContainer, PreviewTemplateContainer} from "./styled";
import {InputClientDataDropZone} from "../../components/InputClientDataDropZone";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {TKycTemplateFieldFillableModel} from "../../../../../newShared/GQLTypes";
import {KycExactDictType} from "../../types";
import {getKycDictionary} from "../../helpers";
import {KYC_DATE_FORMAT} from "../../constants";

// const makeName = (field: KycFormFieldDto) => (
//     `name:${field.name}` +
//     `required:${field.required}` +
//     `system:${field.system}` +
//     `type:${field.type}`
// )

export const KycForm: FC = () => {
    const {location: {search: searchLocation}} = useHistory();
    const token = readFilter(searchLocation)?.token?.[0];

    const {
        formData, result,
        isLoading: {isLoadingForm, isSubmittingForm, isRejectingForm, getDictionaries},
        getForm,
        rejectForm,
        submitForm,
        dicts,
        getDicts
    } = useKYCForm();

    const {setForm, form} = useForm<TKycTemplateFieldFillableModel[]>(formData?.fields || []);

    useEffect(() => {
        if (result?.redirectUrl) {
            const input = document.createElement("input");
            input.name = 'result';
            if (result.action === 'submit') {input.value = 'success'}
            if (result.action === "reject") {input.value = 'rejected'}

            const form = document.createElement('form');
            form.style.display = 'none';
            form.action = result.redirectUrl;
            form.method = 'POST';
            form.target = "_parent";
            form.appendChild(input);
            document.body.appendChild(form);

            setTimeout(() => {form.submit()}, 500)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result?.action, result?.redirectUrl]);

    const isLoading = isLoadingForm || isSubmittingForm || isRejectingForm || getDictionaries;

    const controller = new AbortController();
    useEffect(() => {
        if(token){
            getForm({token}, controller.signal);
            getDicts();
        }

        return () => {
            controller.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchLocation])

    useEffect(() => {
        setForm(formData?.fields || [])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData]);

    const handleChangeCustomField = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.currentTarget.name;
        const value = event.currentTarget.value;
        setForm((prev) => prev.map(e => e.id === id ? {...e, value} : e))
    }

    const handleChangeDate = (id: string, date: string | null) => {
        setForm((prev) => ([...prev.map(e => e.id === id ? {...e, value: date} : e)]))
        // if(date !== null){
        //     try {
        //         const parsed = new Date(Date.parse(date)).toISOString();
        //         setForm((prev) => prev.map(e => e.id === id ? {...e, value: parsed} : e))
        //     }catch (ex){
        //         setForm((prev) => prev.map(e => e.id === id ? {...e, value: null} : e))
        //         console.log(`InputClientDataTemplateDialog handleChangeDate ex`, ex)
        //     }
        // }else{
        //     setForm((prev) => prev.map(e => e.id === id ? {...e, value: null} : e))
        // }
    }

    //comp - upload file - get fileId - fetch outer func - save in form
    const handleFileUpload = (fileId: string, fieldId: string, fileName: string) => {
        setForm((prev) => prev.map(e => e.id === fieldId ? {...e, value: fileId, fileName} : e))
    }

    const handleDeleteUploadedFile = (fieldId: string) => {
        setForm((prev) => prev.map(e => e.id === fieldId ? {...e, value: null, fileName: null} : e))
    }

    const isOk = () => {
        // console.log(`form`, form.fields, form.fields.some(e => (e.required && e.value === null) || (e.value !== null && !e.value.trim().length)));
        return !form.some(e => {
            //looking for bad fields
            if(e.required && e.value === null) return true;
            if((e.required && e.value !== null && typeof e.value === 'string' && !e.value.trim().length)) return true;
            if(e.required && e.type === 'FILE' && (e.fileName === null || e.fileName === undefined || (e.fileName.trim().length === 0))) return true;
            if(e.type === 'DATE' && e.value !== null && e.value !== undefined){
                let parsed;
                try {
                    parsed = new Date(Date.parse(e.value)).toISOString();
                }catch (ex){
                    parsed = null;
                }

                return parsed === null;
            }
            return false;
        })
    }

    const handleChangeSelectField = (id: string, value: KycExactDictType | null) => {
        setForm((prev) => prev.map(e => e.id === id ? {...e, value: value?.key ?? null} : e))
    }

    const imgSize = 40;
    return (
        <Box width={'100%'} maxHeight={'100%'} display={"flex"} flexDirection={"column"} alignItems={"center"} overflow={"auto"} p={'16px'}>
            {(formData?.organization || result?.organization || isLoadingForm) && (
                <Box display={"flex"} alignItems={"center"}>
                    <Box width={imgSize + 'px'} height={imgSize + 'px'}
                         display={"flex"} alignItems={"center"} justifyContent={"center"}>
                        {!isLoadingForm ? (
                            (formData || result)!.organization.logo && <img src={(formData || result)!.organization.logo!} style={{maxWidth: imgSize + 'px', maxHeight: imgSize + 'px'}} alt={'Logo'}/>
                        ) : (
                            <Skeleton height={imgSize} width={imgSize} variant={"rectangular"}/>
                        )}
                    </Box>
                    {/*{!isLoadingForm ? (*/}
                    {/*    <Typography variant={"h1"} sx={{margin: !revDir ? '0 0 0 24px' : '0 24px 0 0'}}>*/}
                    {/*        {(formData || result)!.organization.name}*/}
                    {/*    </Typography>*/}
                    {/*) : (*/}
                    {/*    <Skeleton width={'200px'} height={'44px'} sx={{margin: !revDir ? '0 0 0 24px' : '0 24px 0 0'}}/>*/}
                    {/*)}*/}
                </Box>
            )}

            {isLoadingForm && new Array(7).fill(1).map((_, index) => (
                // <Skeleton width={'100%'} height={'47px'} key={index} sx={{mt: '16px', maxWidth: '330px'}}/>
                <Skeleton width={'100%'} height={'50px'} key={index} sx={{mt: '0', maxWidth: '330px', minHeight: '50px'}}/>
            ))}

            <PreviewTemplateContainer>
                {isLoadingForm && (
                    <Typography variant={"body1"} fontWeight={700} mt={'16px'} align={"center"}><Skeleton width={'200px'}/></Typography>
                )}
                {formData && (
                    <Typography variant={"body1"} fontWeight={700} mt={'16px'} align={"center"}>{`${formData.organization.name} asks you fill form for KYC verification.`}</Typography>
                )}

                {form && form.slice()
                    .sort((a, b) => a.order - b.order)
                    .map(e => {
                        const currentDict = getKycDictionary(dicts, e.dictionary);
                        switch (e.type){
                        case 'TEXT': {
                            return <TextField
                                required={e.required}
                                label={e.name}
                                size={'small'}
                                name={e.id}
                                value={e?.value ?? ''}
                                onChange={handleChangeCustomField}
                            />
                        }
                        case 'NUMBER': {
                            return <TextField
                                type={'number'}
                                required={e.required}
                                label={e.name}
                                size={'small'}
                                name={e.id}
                                value={e?.value ?? ''}
                                onChange={handleChangeCustomField}
                            />
                        }
                        case 'DATE': {
                            return <DatePicker
                                label={e.name}
                                inputFormat={KYC_DATE_FORMAT}
                                views={['day', 'month', 'year']}
                                value={e?.value ?? null}
                                onChange={(newValue) => handleChangeDate(e.id, newValue)}
                                renderInput={(params) => <TextField {...params}
                                                                    error={false}
                                                                    required={e.required}
                                                                    size={'small'}
                                                                    name={e.id}
                                />}
                            />
                        }
                        case 'FILE': {
                            return <FileDropContainer>
                                <Typography fontSize={'12px'} fontWeight={600}>
                                    {e.name}
                                    {e.required && <span style={{color: colors.warningActions.red}}>*</span>}
                                </Typography>

                                <InputClientDataDropZone
                                    onFileUploaded={handleFileUpload}
                                    isLoadingCreateTarget={isLoading}
                                    handleDelete={() => handleDeleteUploadedFile(e.id)}
                                    id={e.id}
                                />
                            </FileDropContainer>
                        }
                            case 'SELECT': {
                                return <Autocomplete
                                    key={e.id}
                                    id="combo-box-demo"
                                    options={currentDict.slice().sort((a, b) => a.order - b.order)}
                                    value={currentDict.find(f => f.key === e?.value)}
                                    isOptionEqualToValue={(a: KycExactDictType, b:  KycExactDictType | undefined) => b !== undefined && a.key === b.key}
                                    getOptionLabel={(e) => e.value}
                                    onChange={(event, value) => handleChangeSelectField(e.id, value)}
                                    sx={{width: '100%'}}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        label={e.name}
                                        required={e.required}
                                        fullWidth
                                        error={false}
                                        size={'small'}
                                        name={e.id}
                                        // sx={{'& fieldset': {borderColor: 'black !important'}}}
                                    />}
                                />
                            }
                        default: return <></>;
                    }
                })}
            </PreviewTemplateContainer>

            {formData && (
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} mt={'16px'}>
                    <LoadingButton loading={isRejectingForm} variant={"text"} startIcon={<DoNotDisturb/>}
                                   color={"error"} sx={{color: colors.text.critical}}
                                   disabled={isLoading}
                                   onClick={() => {rejectForm({token}, controller.signal)}}>
                        {'Reject'}
                    </LoadingButton>

                    <Box width={'10px'} height={'10px'}/>

                    <LoadingButton loading={isSubmittingForm}
                                   disabled={isLoading || !isOk()}
                                   onClick={() => {
                                       formData && submitForm({
                                           token,
                                           fields: form,
                                       }, controller.signal)
                                   }}>
                        {'Send'}
                    </LoadingButton>
                </Box>
            )}

            {result?.action === 'submit' && (
                <>
                    <Typography variant={"body2"} align={"center"} m={'8px 0'}>{'Your request has been sent.'}</Typography>
                    <Typography variant={"h4"} align={"center"}>{'Thank you'}</Typography>
                </>
            )}

            {result?.action === 'reject' && (
                <>
                    <Typography variant={"body2"} align={"center"} m={'8px 0'}>{'Your request has been rejected.'}</Typography>
                </>
            )}

            {!result && !formData && !isLoadingForm && (
                <>
                    <Typography variant={"h4"} align={"center"} m={'8px 0'}>{'Request token not found'}</Typography>
                </>
            )}
        </Box>
    )
}
