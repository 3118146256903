import {useMediaQuery, useTheme} from "@mui/material";

export const useMedia = (isLessThenCustom?: number, isMoreThenCustom? : number) => {

    const theme = useTheme();
    const matches_770Up = useMediaQuery(theme.breakpoints.up('c770'));
    const matches_1440Up = useMediaQuery(theme.breakpoints.up('c1440'));
    const matches_1920Up = useMediaQuery(theme.breakpoints.up('c1920'));
    const matches_1336Up = useMediaQuery(theme.breakpoints.up('c1336'));
    const matches_2300Up = useMediaQuery(theme.breakpoints.up('c2300'));

    const matches_1440Down = useMediaQuery(theme.breakpoints.down('c1440'));
    const matches_1513Down = useMediaQuery(theme.breakpoints.down(1513));
    const matches_1920Down = useMediaQuery(theme.breakpoints.down('c1920'));
    const matches_1336Down = useMediaQuery(theme.breakpoints.down('c1336'));

    const isDesktop = useMediaQuery(theme.breakpoints.up('c770'));
    const isTablet = useMediaQuery(theme.breakpoints.between('c390', 'c770'));
    const isMobile = useMediaQuery(theme.breakpoints.down(501));
    const isLessThen1050 = useMediaQuery(theme.breakpoints.down(1050));
    const isLessThen1170 = useMediaQuery(theme.breakpoints.down(1170));
    const isLessThen1160 = useMediaQuery(theme.breakpoints.down(1160));
    const isLessThenCustomQuery = useMediaQuery(theme.breakpoints.down(isLessThenCustom ?? 0));
    const isMoreThenCustomQuery = useMediaQuery(theme.breakpoints.up(isMoreThenCustom ?? 0));


    return {
        matches_770Up,
        matches_1440Up,
        matches_1920Up,
        matches_1336Up,
        matches_2300Up,
        matches_1336Down,
        matches_1440Down,
        matches_1920Down,
        isTablet,
        isDesktop,
        isMobile,
        isLessThen1050,
        isLessThen1170,
        isLessThen1160,
        matches_1513Down,
        isLessThenCustomQuery,
        isMoreThenCustomQuery,
    }
}