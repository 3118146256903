import {FC} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useMessageDialog} from "./hooks/useMessageDialog";
import {useMainTranslation} from "../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";

export const MessageDialog: FC = () => {

    const {t} = useMainTranslation('', {keyPrefix: 'CommonComponents'})
    const {message, handleClose} = useMessageDialog();

    return message ? (
        <Dialog open={Boolean(message)} onClose={handleClose}>
            <DialogTitle>{message.title}</DialogTitle>

            <DialogContent>{message.message}</DialogContent>

            <DialogActions>
                <Button variant={"text"} sx={{textTransform: 'uppercase'}} onClick={handleClose}>
                    {t('Close')}
                </Button>
            </DialogActions>
        </Dialog>
    ) : null
}