import {ThemeOptions} from "@mui/material/styles/createTheme";
import StarIcon from "@mui/icons-material/Star";
import colors from "./colors"
import {ElementType} from "react";


export const basicTheme: ThemeOptions = {
    direction: 'ltr',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            c390: 390,
            c770: 771,
            c1336: 1336,
            c1440: 1440,
            c1680: 1680,
            c1920: 1920,
            c2048: 2048,
            c2300: 2300,
        }
    },
    typography: {
        fontFamily: `'Noto Sans', sans-serif`,
        fontSize: 14,
        fontWeightLight: 100,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,

        h1: {
            lineHeight: '44px',
            fontSize: 32,
            fontWeight: 700,
            letterSpacing: '0.25px',
            fontFamily: `'Noto Sans', sans-serif`,
        },
        h2: {
            fontSize: 24,
            fontWeight: 500,
            lineHeight: '36px',
            fontFamily: `'Noto Sans', sans-serif`,
            // letterSpacing: '0.20px',
        },
        h3: {
            letterSpacing: '0.15px',
            fontFamily: `'Noto Sans', sans-serif`,
            fontWeight: 400,
            fontSize: 20,
            lineHeight: '28px',
        },
        h4: {
            fontFamily: `'Noto Sans', sans-serif`,
            fontSize: 18,
            fontWeight: 600,
            lineHeight: '24px',
        },
        caption: {
            fontFamily: `'Noto Sans', sans-serif`,
            fontSize: 16,
            fontWeight: 600,
            lineHeight: '20px',
        },
        subtitle1: {
            fontFamily: `'Noto Sans', sans-serif`,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            // letterSpacing: '0.02em',
        },
        subtitle2: {
            fontFamily: `'Noto Sans', sans-serif`,
            fontSize: 16,
            fontWeight: 700,
            lineHeight: '20px',
        },
        body1: {
            fontFamily: `'Noto Sans', sans-serif`,
            fontSize: 16,
            fontWeight: 400,
            lineHeight: '130%',
            letterSpacing: '0.15px',
        },
        body2: {
            fontFamily: `'Noto Sans', sans-serif`,
            fontSize: 14,
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.15px',
        },
    },
    palette: {
        primary: {
            main: '#2962ff',
            light: '#90caf9',
            dark: '#1a237e',
        },
        secondary: {
            main: '#2e7d32',
            light: '#a5d6a7',
            dark: '#1b5e20',
        },
        error: {
            main: '#ea3c1d',
            light: '#ffcdd2',
            dark: '#ea3c1d',
        },
        warning: {
            main: '#eaab00',
            light: '#f5d98a',
            dark: '#c59000',
        },
        info: {
            main: '#4968d3',
            light: '#9ed4fc',
            dark: '#0d4dc4',
        },
        success: {
            main: '#12A778',
            light: '#38e4ae',
            dark: '#08845d',
        },
        divider: '#79747e',

        lightPrimary: {
            main: "#106EBE",
            dark: '#106EBE',
            light: '#90CAF9',
        },
        lightError: {
            main: "#FCE7E7",
            dark: '#c9b5b5',
            light: '#ffffff',
            contrastText: '#FF5959'
        },
        lightInfo: {
            main: '#E3F2FD',
            dark: '#b1bfca',
            light: '#ffffff',
            contrastText: '#A0A2B1'
        },
        lightSuccess: {
            main: '#ECF9F3',
            dark: '#bac6c0',
            light: '#ffffff',
            contrastText: '#00C853'
        },
        lightSecondary: {
            main: '#E8E8E8',
            light: '#ffffff',
            dark: '#b6b6b6',
            contrastText: '#0000008A'
        },
        accent: {
            main: '#A0A2B1',
            dark: '#717382',
            light: '#d1d3e3',
            contrastText: '#FFFFFF'
        },
        accentDark: {
            main: '#23273F',
            dark: '#000019',
            light: '#4c4f6a',
            contrastText: '#FFFFFF'
        }
    },
    zIndex: {
        drawer: 1220,
        modal: 3100,
        snackbar: 3200,
        tooltip: 3300,
    },
    components: {
        MuiFormLabel: {
            styleOverrides: {
                asterisk: {color:"red"},
            },
        },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },

        MuiIconButton: {
            defaultProps: {
                size: 'large'
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: '14px 8px',
                    "&.Mui-selected": {
                        backgroundColor: '#E3F2FD'
                    }
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
                size: 'large',
            },
            variants: [
                {
                    props: {variant: 'outlined', color: 'success'}, style: ({theme}) => ({
                        border: `1px solid ${theme.palette.success.main}`,
                    }),
                },
                {
                    props: {variant: 'contained'}, style: ({theme}) => ({
                        border: `1px solid transparent`,
                    }),
                }
            ],
            styleOverrides: {
                root: (props) => {
                    return {
                        fontSize: '15px',
                        lineHeight: '20px',
                        letterSpacing: '0.46px',
                        textTransform: 'none',
                        padding: '10px 20px',
                        fontWeight: '600',
                        // background: '#2962FF',

                        "&.Mui-disabled": {
                            "backgroundColor": "#E0E0E0",
                            "color": "#A19F9D"
                        },

                        '& .MuiButton-startIcon': {margin: props.theme.direction === 'ltr' ? '0 8px 0 -4px': '0 -4px 0 8px'},
                        '& .MuiButton-endIcon': {margin: props.theme.direction === 'rtl' ? '0 8px 0 -4px': '0 -4px 0 8px'},



                        // Primary
                        "&.MuiButton-containedPrimary": {
                            backgroundColor: "#2962FF",
                            "&.Mui-disabled": {
                                "backgroundColor": "#E0E0E0",
                                "color": "#A19F9D"
                            },
                            "&:hover": {
                                "backgroundColor": "#448AFF",
                            },
                            // "&:focus": {
                            //     "backgroundColor": "#2962FF",
                            // }
                        },


                        // Secondary
                        "&.MuiButton-containedSecondary": {
                            backgroundColor: "#006D41",
                            "&.Mui-disabled": {
                                "backgroundColor": "#E0E0E0",
                                "color": "#A19F9D"
                            },
                            "&:hover": {
                                "backgroundColor": "#4CAF50",
                            },
                            // "&:focus": {
                            //     "backgroundColor": "#2E7D32",
                            // }

                        },
                    }
                },
                outlined: {
                    border: '1px solid #616161',
                    color: '#343440',
                    ':hover': {
                        'backgroundColor': '#F7F7F7',
                        'border': '1px solid #616161',
                    },
                },
                text: {
                    textTransform: "uppercase",
                    color: '#2962FF',

                    "&.Mui-disabled": {
                        "backgroundColor": "inherit",
                    }
                },
                sizeLarge: {
                    fontWeight: 600,
                    fontSize: '15px',
                    lineHeight: '20px',
                    letterSpacing: '0.46px',
                },
                sizeMedium: {
                    fontSize: '14px',
                    lineHeight: '18px',
                    letterSpacing: '0.4px',
                },
                sizeSmall: {
                    fontSize: '13px',
                    padding: '9px 8px',
                    lineHeight: '16px',
                    letterSpacing: '0.4px',
                }
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-root:not(.MuiFormLabel-filled, .Mui-focused, .MuiInputLabel-shrink, *.MuiInputBase-inputSizeSmall)': {
                        // lineHeight: 1,
                        // top: '-4px',
                        // '&.customLabel': {
                        //     top: '12px',
                        // },
                    },
                    '&.customLabel': {
                        // top: '16px',
                    },
                }
            }
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.15px',
                    marginLeft: 0
                }
            }
        },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    'input.MuiInputBase-input, div.MuiInputBase-input': {
                        padding: '12px',
                        lineHeight: '16px',
                        fontSize: '16px',
                        fontWeight: 400,
                        letterSpacing: '0.15px',
                        color: '#343440',
                    },
                    '.MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                    },
                },
                multiline: {
                    '&': {
                        padding: '12px',
                        lineHeight: '16px',
                        fontSize: '16px',
                        fontWeight: 400,
                        letterSpacing: '0.15px',
                        color: '#343440',
                    },
                },
                sizeSmall: {
                    height: '40px',
                },
            }
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                variant: 'outlined',
            },
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-sizeSmall': {
                        lineHeight: 'initial !important'
                    },
                    '& .MuiInputLabel-root:not(*.MuiInputBase-inputSizeSmall)': {
                        lineHeight: 'unset'
                    },
                    // '& .MuiInputBase-sizeSmall': {
                    //     lineHeight: 'initial'
                    // }
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 40,
                    height: 20,
                    padding: 0,
                    borderRadius: '10px',
                    display: 'flex',

                    '&:active': {
                        '& .MuiSwitch-thumb': {
                            width: 15,
                        },
                        '& .MuiSwitch-switchBase.Mui-checked': {
                            transform: 'translateX(9px)',
                        },
                    },

                    '& .MuiSwitch-switchBase': {
                        padding: 4,
                        border: '',
                        color: '#A19F9D',

                        '&:hover': {
                            color: '#343440',

                            '& + .MuiSwitch-track': {
                                borderColor: '#343440',
                            }
                        },


                        '&.Mui-checked': {
                            transform: 'translateX(19px)',
                            color: '#fff',
                            '& + .MuiSwitch-track': {
                                opacity: 1,
                                backgroundColor: '#1890ff',
                                border: '2px solid #1890ff',
                            },
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                        width: 12,
                        height: 12,
                        borderRadius: 6,
                        transition: 'all 0.2s',
                    },

                    '& .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: '#fff',
                        boxSizing: 'border-box',
                        borderRadius: '10px',
                        border: '2px solid #A19F9D',
                    }
                }
            }
        },
        MuiRadio: {
            defaultProps: {
                color: "default",
            },
            styleOverrides: {
                root: {
                    '& > span': {
                        color: colors.primary.blue
                    }
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.primary.blue,
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-root': {
                        borderRadius: '3px',
                        width: '600px',
                        minHeight: '158px',
                        boxShadow: ' 0px 8px 20px rgba(125, 151, 174, 0.16), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
                    }
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.backgrounds.grey_light,
                    borderBottom: `1px solid ${colors.stroke.grey}`,
                    width: '100%',
                    padding: '16px',
                    fontSize: '16px',
                    fontWeight: 700,
                    color: colors.grayText,
                    margin: '0 0 20px 0'
                }
            },
            defaultProps: {
                component: 'div' as (ElementType<any> & "span") | undefined
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: '400',
                    letterSpacing: '0.15px',
                    color: colors.darkBlueText,
                    padding: '0 24px',

                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.backgrounds.grey_light,
                    borderTop: `1px solid ${colors.stroke.grey}`,
                    width: '100%',
                    padding: '8px 24px',
                    gap: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    margin: '20px 0 0 0'
                }
            }
        },
        MuiTabs: {
            defaultProps: {

            },
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                    minHeight: '50px',
                    '& .MuiTabs-flexContainer': {
                        borderBottom: `2px solid ${colors.stroke.grey}`,
                    }
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0.15px',
                    // margin: '-5px 0',
                }
            }
        },
        MuiTable: {
            styleOverrides: {
                root: ({theme, ownerState}) => ({
                    '& * .MuiTableCell-root': {
                        ...(ownerState.size === 'micro' ? {
                            padding: '0 16px',
                            fontSize: '14px',
                            [theme.breakpoints.down('c1336')]: {
                                fontSize: '12px',
                            }
                        } : {})
                    }
                })
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: colors.backgrounds.blue_light_1,
                        '&:hover': {
                            backgroundColor: '#E3F2FD',
                        }
                    },
                    '&:last-child td, &:last-child th': { borderBottom: 'none' },

                    '&.MuiTableRow-hover:hover': {
                        backgroundColor: colors.backgrounds.grey_light,
                        cursor: 'pointer',
                    },

                    '&.disabled *': {
                        // backgroundColor: colors.backgrounds.grey_dark,
                        // filter: 'opacity(0.5) grayscale(1)',
                    },

                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: ({theme}) => ({
                    textAlign: 'start',
                    borderBottom: '1px solid #E0E0E0',
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    letterSpacing: '0.15px'
                }),
                head: {
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: '20px'
                }
            }
        },
        MuiRating: {
            defaultProps: {
                emptyIcon: <StarIcon style={{ color: '#D2D0CE' }} />,
            },
            styleOverrides: {
                root: {
                    color: '#106EBE',
                },
            }
        },

        MuiAppBar: {
            styleOverrides: {
                root: {
                    //TODO color app bar
                    backgroundColor: 'white'
                }
            }
        },

        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: '#000',
                    padding: '16px',
                    background: 'inherit',
                    boxShadow: '0 4.8px 14.4px rgba(0, 0, 0, 0.18), 0 25.6px 57.6px rgba(0, 0, 0, 0.22)',

                }
            }
        },

        MuiAccordion: {
            defaultProps: {
                disableGutters: true
            },
            styleOverrides: {
                root: {
                    '&:before': {
                        position: 'initial'
                    }
                }
            }
        },

        MuiAlert: {
            defaultProps: {
                variant: "filled",
            },
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
                filledWarning: {
                    backgroundColor: colors.decorative.orange,
                    color: "white"
                },

                // '&.MuiAlert-filledWarning': {
                //     backgroundColor: colors.decorative.orange
                // }
            }
        },
        MuiTypography: {
            variants: [{
                props: {
                    variant: 'bodySmall',
                },
                style: {
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '15.6px',
                    letterSpacing: '0.15px'
                }
            },
                {
                    props: {
                        variant: 'bodySemiBold',
                    },
                    style: {
                        fontSize: '14px',
                        fontWeight: 600,
                        lineHeight: '130%',
                        letterSpacing: '0.15px'
                    }
                }
            ]
        }
    }

};