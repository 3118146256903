import {gql} from "@apollo/react-hooks";

export const kycFormFragment = gql`
    fragment kycFormFragment on KycFormModelRefactor {
        organization {
            id
            name
            logo
        }

        fields {
            checkAlias {
                alias
                checkId
            }
            fileName
            id
            name
            order
            required
            requiredForTemplate
            system
            systemAlias
            type
            value
            dictionary
        }
    }
`