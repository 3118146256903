import colors from "../../../newShared/theme/colors";
import {TKycTemplateFieldFillableModelInput} from "../../../newShared/GQLTypes";
import moment from 'moment';
import { KycDictionaryType, KycExactDictType } from "./types";

export const getKYCRequestStatus = (t: (str: string) => string, status?: string): {color: string, status: string} => {
    switch (status) {
        case 'IN_PROGRESS': {return {color: colors.decorative.blue_light, status: t('In progress')}}
        case 'REVIEW': {return {color: colors.text.blue, status: t('Review')}}
        case 'COMPLETED': {return {color: colors.warningActions.green, status: t('Completed')}}
        case 'REJECTED': {return {color: colors.text.critical, status: t('Rejected')}}
        case 'REJECTED_BY_USER': {return {color: colors.decorative.orange, status: t('Rejected by user')}}
        default: {return {color: colors.text.dark, status: status || ''}}
    }
}

// export const removeTimeZoneDateFields = (fields:  TKycTemplateFieldFillableModelInput[]): TKycTemplateFieldFillableModelInput[] => {
//     let res: TKycTemplateFieldFillableModelInput[] = [];
//     fields.forEach(e => {
//         if(e.type === 'DATE' && e.value){
//             const momentDate = moment(e.value).toISOString(true);
//             res.push({...e, value: momentDate, fileName: e.fileName ?? null});
//         }else{
//             res.push({...e, fileName: e.fileName ?? null, value: e.value ?? null});
//         }
//     })
//     return res;
// }

export const removeTimeZoneDateFields = (fields: TKycTemplateFieldFillableModelInput[]): TKycTemplateFieldFillableModelInput[] => {
    let res: TKycTemplateFieldFillableModelInput[] = [];
    fields.forEach(e => {
        if(e.type === 'DATE' && e.value){
            const momentDate = moment(e.value).format();
            // console.log(`removeTimeZoneDateFields momentDate`, momentDate)
            res.push({...e, value: momentDate, fileName: e.fileName ?? null});
        }else{
            res.push({...e, fileName: e.fileName ?? null, value: e.value ?? null});
        }
    })
    return res;
}

export const getKycDictionary = (dictionaries: KycDictionaryType, key: string | null | undefined): KycExactDictType[] => {
    if(key && Object.keys(dictionaries).some(e => e === key)){
        let _key: keyof KycDictionaryType = key as keyof KycDictionaryType;
        const currentDict: KycExactDictType[] = dictionaries[_key];
        return currentDict ?? [];
    }else{
        return [];
    }
}